import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/builds/everyone/dcdcweb-saturday/src/components/Layout/index.js";
import { Link } from "gatsby";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="h1" components={components}>{`Devs for Hire!`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here at DCDC, our pool of talented developers have a wealth of tech experience that they're willing to share. We welcome all consultancy and contract engagements on interesting projects.`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`We can work alone or in teams.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`We can provide end to end expertise.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`We can facilitate agile transformations.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`We can handle any tech stack.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Drop us an email for more information.`}</MDXTag>
      <MDXTag name="p" components={components}><p><Link to="contact_us">{`contact us`}</Link></p></MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    